/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useRouter } from "next/router";

import { useProducts } from "./products";

import type { Invoice, ProductCaseInput, ProductSummary } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput } from "hooks/datalist";
import { useProject } from "contexts/project";
import { useAlerts } from "contexts/alerts";
import { useDialog } from "contexts/dialog";
import { useAuth } from "contexts/auth";
import { useGroup } from "contexts/group";
import { getInvoiceInputs } from "utils/invoice";
import { ProductSummariesFilter } from "utils/productSummary";
import { productSummariesByProjectId } from "graphql/queries";

interface InvoicesContextValue {
  loading: boolean;
  productSummariesLoading: boolean;
  invoices: Invoice[];
  productSummariesForInvoice: [];
  lastUpdatedAt: string;
  create: (input: GraphQLInput) => Promise<void>;
  update: (input: GraphQLInput) => Promise<void>;
  remove: (id: string) => Promise<void>;
  importWithDeleting: (
    id: string,
    productCases?: ProductCaseInput[]
  ) => Promise<void>;
  importWithoutDeleting: (
    id: string,
    productCases?: ProductCaseInput[]
  ) => Promise<void>;
  export: (id: string) => Promise<void>;
  refetch: () => Promise<void>;
  fetchProductSummaries: (filter?: ProductSummariesFilter) => Promise<void>;
}

interface InvoicesContextProps {
  children: ReactNode;
}

const InvoicesContext = createContext<InvoicesContextValue>({
  invoices: [],
  productSummariesForInvoice: [],
  loading: false,
  productSummariesLoading: false,
  lastUpdatedAt: "",
  refetch: () => Promise.resolve(),
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  importWithDeleting: () => Promise.resolve(),
  importWithoutDeleting: () => Promise.resolve(),
  export: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  fetchProductSummaries: () => Promise.resolve(),
});

const isInvoice = (data: any): data is Invoice => {
  return data && data.id && data.projectId && data.name;
};

export const InvoicesProvider = ({ children }: InvoicesContextProps) => {
  const { project } = useProject();
  const { data, loading, update, create, remove, refetch } = useDatalist({
    query: `invoicesByProjectId`,
    variables: {
      projectId: project?.id,
    },
    sort: (a, b) => {
      // orderというfieldがあったらそれで、なかったらcreatedAtでソートする
      if (a.order && b.order) {
        return a.order - b.order;
      } else {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
    },
  });

  const defaultProductSummariesFilter: ProductSummariesFilter = {
    includeIncompleteProduct: false,
    includeBilledProduct: false,
    scheduleIds: undefined,
  };

  const [productSummaries, setProductSummaries] = useState<any>(data);
  const [productSummariesLoadingData, setProductSummariesLoadingData] =
    useState<boolean>(false);

  const fetchProductSummaries = async (
    filter: ProductSummariesFilter = defaultProductSummariesFilter
  ) => {
    if (!project) {
      setProductSummaries([]);
    }

    setProductSummariesLoadingData(true);
    const res = (
      await API.graphql(
        graphqlOperation(productSummariesByProjectId, {
          input: { projectId: project!.id, filter },
        })
      )
    ).data.productSummariesByProjectId.items.sort(
      (a: ProductSummary, b: ProductSummary) => {
        return (a?.productOrder as number) - (b?.productOrder as number);
      }
    );

    setProductSummaries(res);
    setProductSummariesLoadingData(false);
  };

  const { open } = useDialog();
  const {
    products,
    update: updateProduct,
    refetch: refetchProduct,
  } = useProducts();
  const { addAlert, setSnooze } = useAlerts();
  const { user } = useAuth();
  const { group } = useGroup();
  // 最終更新日時
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>("");
  const router = useRouter();

  const createInvoice = async (input: GraphQLInput) => {
    // null check
    if (!group || !project || !user) return;
    const res = await create(
      "createInvoice",
      getInvoiceInputs(
        group,
        project,
        user?.id,
        {
          name: input.name,
          invoiceNo: input.invoiceNo,
          issueDate: input.issueDate,
          billingDate: input.billingDate,
          recordingDate: input.recordingDate,
        },
        group?.tenant?.host ?? ""
      )
    );
    refetch({ projectId: project?.id });
    if (!isInvoice(res)) return;
    const url = `/projects/${project?.id}?page=invoices&invoiceId=${res.id}`;
    router.push(url);
  };

  const removeInvoice = async (id: string) => {
    open({
      title: "請求を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        setSnooze(["success"]);
        addAlert({ message: "削除中です", severity: "info" });
        // invoiceLineのidを持つcaseのisBilledとinvoiceLineIdを初期化する
        const invoice = data?.find((invoice: any) => invoice.id === id);
        if (invoice) {
          const invoiceLineIds = invoice.invoiceLines?.items.map(
            (line: any) => line.id
          );
          const productUpdatePromises: Promise<void>[] = [];
          for (const product of products) {
            if (product.cases) {
              const cases = product.cases;
              let updated = false;
              for (const c of cases) {
                if (
                  c?.invoiceLineId &&
                  invoiceLineIds.includes(c.invoiceLineId)
                ) {
                  c.invoiceLineId = null;
                  c.isBilled = false;
                  updated = true;
                }
              }
              if (updated) {
                productUpdatePromises.push(
                  updateProduct({
                    id: product.id,
                    cases,
                  })
                );
              }
            }
          }
          await Promise.all(productUpdatePromises);
          await refetchProduct({ progress: false });
        }
        await remove("deleteInvoice", { id });
        addAlert({
          message: "データが削除されました",
          severity: "success",
          force: true,
        });
        setSnooze([]);
      },
    });
  };

  const updateInvoice = async (input: GraphQLInput) => {
    await update("updateInvoice", {
      ...input,
    });
  };

  const importInvoiceDataWithDeleting = async (
    id: string,
    productCases?: ProductCaseInput[]
  ) => {
    await update("importInvoiceLines", {
      id,
      products: productCases,
      withDeletion: true,
      includeOtherQuoteLines: true,
    });
    await refetchProduct({ progress: false });
    const now = new Date();
    setLastUpdatedAt(now.toLocaleString());
    // await refetch({ projectId: project?.id }, { progress: false });
  };

  const importInvoiceDataWithoutDeleting = async (
    id: string,
    productCases?: ProductCaseInput[]
  ) => {
    await update("importInvoiceLines", {
      id,
      products: productCases,
      withDeletion: false,
      includeOtherQuoteLines: false,
    });
    await refetchProduct({ progress: false });
    const now = new Date();
    setLastUpdatedAt(now.toLocaleString());
  };

  const exportInvoiceData = async (id: string) => {
    addAlert({ message: "同期中です", severity: "info" });
    await update("exportInvoiceToSalesForce", {
      id,
    });
    addAlert({ message: "同期が成功しました", severity: "success" });
  };

  useEffect(() => {
    if (!project) return;
    refetch({ projectId: project.id });
    fetchProductSummaries(defaultProductSummariesFilter);
  }, [project]);

  return (
    <InvoicesContext.Provider
      value={{
        invoices: data,
        productSummariesForInvoice: productSummaries,
        loading,
        productSummariesLoading: productSummariesLoadingData,
        lastUpdatedAt,
        refetch: () => refetch({ projectId: project?.id }),
        create: createInvoice,
        remove: removeInvoice,
        update: updateInvoice,
        importWithDeleting: importInvoiceDataWithDeleting,
        importWithoutDeleting: importInvoiceDataWithoutDeleting,
        export: exportInvoiceData,
        fetchProductSummaries,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export const useInvoices = () => {
  const invoicesContext = useContext(InvoicesContext);

  if (invoicesContext === undefined) {
    throw new Error("useInvoices must be within InvoiceProvider");
  }

  return invoicesContext;
};
