import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Avatar, List } from "@mui/material";
import { useRouter } from "next/router";
import { green } from "@mui/material/colors";

import { useProject } from "contexts/project";
import { useVanplans } from "contexts/vanplans";
import { ProgressIcon } from "utils/product";
import { getVanplanCompletionRate } from "utils/vanplan/vanplan";
import { Vanplan } from "API";

interface ContainerProps {
  vanplan: Vanplan;
  toggleDrawer: () => void;
}

const ContainerItems = ({ toggleDrawer, vanplan }: ContainerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("vanplans");
  const { project } = useProject();
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (!!query.containerId) {
      setCurrentPage(`${query.vanplanId}-${query.containerId}`);
      if (query.vanplanId === vanplan?.id) setOpen(true);
    } else if (!!query.vanplanId) {
      setCurrentPage(query.vanplanId as string);
    } else {
      setCurrentPage("");
    }
  }, [query, vanplan]);

  if (!project || !vanplan) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(
      `/projects/${project.id}?page=vanplans&vanplanId=${vanplan.id}`
    );
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          sx={{ pl: 4 }}
          selected={currentPage === vanplan.id}
          onClick={handleClick}
        >
          <ListItemIcon>
            <ProgressIcon
              progress={getVanplanCompletionRate(vanplan) * 100}
              size={30}
            />
          </ListItemIcon>
          <ListItemText primary={vanplan.name} />
        </ListItemButton>
        <div className="cursor-pointer flex items-center mr-4">
          {open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )}
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {vanplan.containers &&
          vanplan.containers.items.length > 0 &&
          vanplan.containers.items
            .sort((a, b) => (a?.order ?? 999) - (b?.order ?? 999))
            .map((container) => (
              <Link
                key={`vanplan_${vanplan.id}_container_${container?.id}`}
                href={`/projects/${project.id}?page=vanplans&vanplanId=${vanplan.id}&containerId=${container?.id}`}
                prefetch={false}
              >
                <ListItemButton
                  sx={{ pl: 8 }}
                  selected={currentPage === `${vanplan.id}-${container?.id}`}
                >
                  <ListItemIcon>
                    {container?.shipCompleted ? (
                      <Avatar sx={{ bgcolor: green[800] }} aria-label="status">
                        済
                      </Avatar>
                    ) : (
                      <Avatar sx={{ bgcolor: "gray" }} aria-label="status">
                        未
                      </Avatar>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={`${container?.name}`} />
                </ListItemButton>
              </Link>
            ))}
      </Collapse>
    </>
  );
};

interface Props {
  toggleDrawer: () => void;
}

const VanplanItems = ({ toggleDrawer }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("vanplans");
  const router = useRouter();
  const { project } = useProject();
  const { vanplans, loading } = useVanplans();

  const { query } = router;

  useEffect(() => {
    if (!query.vanplanId && !query.containerId) {
      setCurrentPage(query.page as string);
    } else if (!!query.vanplanId && query.page === "vanplans") {
      setCurrentPage("");
      setOpen(true);
    } else if (!!query.vanplanId && !!query) {
      setCurrentPage("");
      setOpen(true);
    } else {
      setCurrentPage("");
    }
  }, [query, router.isReady, vanplans]);

  useEffect(() => {
    if (router.isReady) {
      if (query.containerId) {
        setCurrentPage(`${query.vanplanId}-${query.containerId}`);
      } else if (query.vanplanId) {
        setCurrentPage(query.vanplanId as string);
      } else {
        setCurrentPage("");
      }
    }
  }, [router.isReady, query.vanplanId, query.containerId]);

  if (!project || !vanplans) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(`/projects/${project.id}?page=vanplans`);
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          onClick={handleClick}
          selected={currentPage === "vanplans"}
        >
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="バンプラン" />
        </ListItemButton>
        <div className="cursor-pointer flex items-center mr-4">
          {open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )}
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loading && vanplans.length === 0 && <LinearProgress />}
          {vanplans.map((vanplan) => (
            <ContainerItems
              vanplan={vanplan}
              toggleDrawer={toggleDrawer}
              key={vanplan.id}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default VanplanItems;
