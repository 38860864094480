export type ProductSummariesFilterCache = {
  includeIncompleteProduct: boolean;
  includeBilledProduct: boolean;
  // バックエンド的にはstring[]でもOKだが、現状フロント側では1つしかスケジュールを選択していないので[string]としている
  scheduleIds: [string] | undefined;
};

export interface CaseInput {
  order: number;
  [key: string]: any;
}

export type ProductSummariesFilter = Partial<ProductSummariesFilterCache>;

export const defaultProductSummariesFilter: ProductSummariesFilterCache = {
  includeIncompleteProduct: false,
  includeBilledProduct: true,
  scheduleIds: undefined,
};

export interface ProductSummary {
  cn?: number; // 現在のProductSummaryの順序での1オリジンの番号、DBに保管されることはなく利用する場合(reportやProductSummaryView)にその場で設定する
  productId: string;
  productName: string;
  pricingId: string;
  packageTypeId: string;
  name: string;
  order: number;
  productOrder: number;
  caseOrder: number;
  outerLength: number;
  outerWidth: number;
  outerHeight: number;
  grossWeight: number;
  m3: number;
  phaseName: string;
  scheduleName: string;
  shippingDate: string;
  material: string;
  destination: string;
  managementNumber: string;
  isBilled: boolean;
  invoiceLineId: string;
  readyToPack: boolean;
  vanplanCreated: boolean;
}

export const convertProductSummariesFilterToCache = (
  filter: ProductSummariesFilter
): ProductSummariesFilterCache => {
  if (isProductSummariesFilterCache(filter)) {
    return filter;
  }

  // プロパティがない場合はデフォルトの値を使う
  const result: ProductSummariesFilterCache = {
    ...defaultProductSummariesFilter,
  };

  // プロパティがある場合のみその値で上書きする => 結果的にプロパティがないものはデフォルトの値が使われる
  (Object.keys(filter) as (keyof ProductSummariesFilterCache)[]).forEach(
    (key) => {
      if (filter[key] !== undefined) {
        result[key] = filter[key] as any;
      }
    }
  );

  return result;
};

export const isProductSummariesFilterCache = (
  filter: ProductSummariesFilter
): filter is ProductSummariesFilterCache => {
  return (
    typeof filter.includeIncompleteProduct === "boolean" &&
    typeof filter.includeBilledProduct === "boolean" &&
    // {scheduleIds: undefined}と{}(プロパティが存在しない)を区別する
    "scheduleIds" in filter &&
    (filter.scheduleIds === undefined || Array.isArray(filter.scheduleIds))
  );
};

interface SortableSummary {
  order?: number;
  productOrder: number;
  caseOrder: number;
}

export const sortSummariesBySummaryOrder = <T extends SortableSummary>(
  summaries: T[]
): T[] => {
  // 1. order(default: 9999)で昇順に並べる
  // 2. (order が同じなら)productOrderで昇順に並べる
  // 3. (productOrder が同じなら)caseOrderで昇順に並べる
  // 4. (基本ないはずだが、caseOrder が同じなら)そのままの並びとする
  summaries.sort((a, b) => {
    const orderA = a.order !== undefined ? a.order : 9999;
    const orderB = b.order !== undefined ? b.order : 9999;

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    if (a.productOrder !== b.productOrder) {
      return a.productOrder - b.productOrder;
    }

    if (a.caseOrder !== b.caseOrder) {
      return a.caseOrder - b.caseOrder;
    }

    return 0;
  });
  return summaries;
};

/**
 * ProductSummaryの配列からproductIdとcaseOrderをキーとしてorderを取得する
 * このmappingは並び替え機能が一々APIを叩かなくてもできるようにするために使われる(メモリ上の更新)
 * @param data ProductSummaryの配列
 * @returns { [key: string]: number } productIdとcaseOrderをキーとしてorderを取得するmapping
 */
export const makeSummaryOrderMapping = (
  data: ProductSummary[]
): { [key: string]: number } => {
  if (!data?.length || data.length === 0) {
    return {};
  }
  const mapping: { [key: string]: number } = {};
  data.forEach((summary) => {
    mapping[`${summary.productId}|${summary.caseOrder}`] = summary.order;
  });
  return mapping;
};

/**
 * mappingをproductIdとcaseOrderに分割しCaseのsummaryOrderを更新するためのinputにする
 * @param mappings productIdとcaseOrderをキーとしたorderのmapping
 * @returns summaryOrderを更新するためのinput, productIdごとにまとめられている
 */
export const summaryOrderMappingToCaseInput = (mappings: {
  [key: string]: number;
}): { productId: string; caseInputs: CaseInput[] }[] => {
  const caseInputs: { productId: string; caseInputs: CaseInput[] }[] = [];
  Object.keys(mappings).forEach((key) => {
    const [productId, caseOrder] = key.split("|");
    const order = mappings[key];
    // ProductSummaryのOrderはCaseではsummaryOrderとして扱われる
    const caseInput: CaseInput = {
      order: parseInt(caseOrder, 10),
      summaryOrder: order,
    };
    const target = caseInputs.find((c) => c.productId === productId);
    if (target) {
      target.caseInputs.push(caseInput);
    } else {
      caseInputs.push({
        productId,
        caseInputs: [caseInput],
      });
    }
  });
  return caseInputs;
};

/**
 * ProductSummaryのIDを作成する(`${productId}|${caseOrder}`)
 * @param p 対象のProductSummary
 * @returns ID
 */
export const makeProductSummaryId = (
  p: ProductSummary | { [key: string]: number | string }
) => {
  return `${p.productId}|${p.caseOrder}`;
};

/**
 * productSummaryのIDからproductIdとcaseOrderを取得する
 * @param id productSummaryのID
 * @returns productIdとcaseOrder
 */
export const splitProductSummaryId = (
  id: string
): { productId: string; caseOrder: number } => {
  const [productId, caseOrder] = id.split("|");
  return { productId, caseOrder: parseInt(caseOrder, 10) };
};
