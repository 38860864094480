import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useProject } from "./project";

import type {
  CreateVanplanAndContainerInput,
  DeleteVanplanAndContainerInput,
  Vanplan,
} from "API";
import useDatalist from "hooks/datalist";

interface VanplansContextValue {
  loading: boolean;
  vanplans: Vanplan[];
  create: (input: any) => void;
  createAll: (input: CreateVanplanAndContainerInput) => Promise<string>;
  update: (input: any) => void;
  remove: (id: string) => void;
  refetch: () => void;
}

interface VanplansContextProps {
  children: ReactNode;
  variables?: { [key: string]: any };
}

const VanplansContext = createContext<VanplansContextValue>({
  vanplans: [],
  loading: false,
  create: () => null,
  createAll: () => Promise.resolve(""),
  update: () => null,
  remove: () => null,
  refetch: () => null,
});

export const VanplansProvider = ({
  children,
  variables,
}: VanplansContextProps) => {
  const { project } = useProject();
  const {
    data,
    loading,
    refetch,
    create,
    remove,
    update,
    nextToken,
    appendNext,
  } = useDatalist({
    query: "vanplansByProjectId",
    variables: {
      groupId: project?.id,
    },
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  useEffect(() => {
    if (!project?.id) return;
    refetch({ projectId: project.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, project?.id]);

  const createVanplan = (input: any) => {
    create("createVanplan", {
      ...input,
      projectId: project?.id,
    });
  };

  const createAll = async (input: CreateVanplanAndContainerInput) => {
    const result = (await create("createVanplanAndContainer", {
      ...input,
    })) as unknown as { vanplanId: string };
    refetch({ projectId: project?.id }, { progress: false });

    // idを返す
    return result?.vanplanId ?? "";
  };

  const updateVanplan = (input: any) => {
    update("updateVanplan", {
      ...input,
    });
  };

  const removeVanplan = async (id: string) => {
    const deleteInput: DeleteVanplanAndContainerInput = {
      vanplanId: id,
    };
    await remove("deleteVanplanAndContainer", { ...deleteInput });
    refetch({ projectId: project?.id }, { progress: false });
  };

  const refetchVanplans = () => {
    refetch({ projectId: project?.id }, { progress: false });
  };

  return (
    <VanplansContext.Provider
      value={{
        vanplans: data,
        loading,
        create: createVanplan,
        createAll,
        update: updateVanplan,
        remove: removeVanplan,
        refetch: refetchVanplans,
      }}
    >
      {children}
    </VanplansContext.Provider>
  );
};

export const useVanplans = () => {
  const vanplansContext = useContext(VanplansContext);

  if (vanplansContext === undefined) {
    throw new Error("useVanplans must be within VanplansProvider");
  }

  return vanplansContext;
};
