import { makeProductSummaryId, ProductSummary } from "../productSummary";

import {
  ContainerMaster,
  CreateGroupsContainerMastersInput,
  DeleteGroupsContainerMastersInput,
  Group,
  GroupsContainerMasters,
  UpdateGroupsContainerMastersInput,
} from "API";
import { SelectableListRowType } from "components/SelectableList";

// 回転方向用の定数
export const DEFAULT_ORIENTATION: { label: string; value: string } = {
  label: "LW回転",
  value: "LW_ROTATE",
};

export const ORIENTATIONS: { label: string; value: string }[] = [
  DEFAULT_ORIENTATION,
  { label: "長さ方向固定", value: "L_LOCK" },
  { label: "幅方向固定", value: "W_LOCK" },
  { label: "全面回転可", value: "ALL_ROTATE" },
];

// バンプラン作成用のコンテナ情報、これを用いてバンプラン(LoadPlan)を作成する
export type ContainerInfo = {
  cn: number;
  productSummaryId: string;
  productSummaryName: string;
  order: number;
  productOrder: number;
  caseOrder: number;
  productName: string;
  managementNumber: string;
  pricingId: string;
  grossWeight: number;
  outerHeight: number;
  outerLength: number;
  outerWidth: number;
  notStackable: boolean;
  bottomOnly: boolean;
  maxStacking: number | null;
  orientation: string;
  casePriority: number | null;
};

/**
 * バンプラン作成用のコンテナ情報を作成する
 * 基本的にはproductSummariesから作成するが、以前の情報がある場合はそれを利用する
 * またnotStackable以降について以前の情報がない場合は、groupから取得する
 * @param productSummaries ProductSummaries
 * @param beforeInfoList 以前のコンテナ情報(Wizard遷移などで以前の情報がある場合がある)
 * @param group 所属する事業所
 * @returns 今回利用するコンテナ情報
 */
export const createContainerInfos = (
  productSummaries: ProductSummary[],
  beforeInfoList: ContainerInfo[],
  group: Group
): ContainerInfo[] => {
  const containerInfo: ContainerInfo[] = productSummaries.map(
    (productSummary: ProductSummary, index: number) => {
      const before = beforeInfoList.find(
        (beforeInfo) =>
          beforeInfo.productSummaryId === makeProductSummaryId(productSummary)
      );
      // notStackable, bottomOnly, maxStacking, orientationについては以前の情報があればそれを利用する、なければgroupから取得する
      // その他の値はproductSummaryから取得する
      return {
        cn: productSummary.cn ?? index + 1,
        productSummaryId: makeProductSummaryId(productSummary),
        productSummaryName: productSummary.name,
        order: productSummary.order,
        productOrder: productSummary.productOrder,
        caseOrder: productSummary.caseOrder,
        productName: productSummary.productName,
        managementNumber: productSummary.managementNumber,
        pricingId: productSummary.pricingId,
        grossWeight: productSummary.grossWeight,
        outerHeight: productSummary.outerHeight,
        outerLength: productSummary.outerLength,
        outerWidth: productSummary.outerWidth,
        notStackable: before?.notStackable ?? group.notStackable ?? false,
        bottomOnly: before?.bottomOnly ?? group.bottomOnly ?? false,
        maxStacking: before?.maxStacking ?? group.maxStacking ?? null,
        orientation:
          before?.orientation ?? group.orientation ?? DEFAULT_ORIENTATION.value,
        casePriority: before?.casePriority ?? 1,
      };
    }
  );
  return containerInfo;
};

/**
 * コンテナマスタと事業所の関連情報をコンテナマスタに変換する関数
 * @param groupsContainerMasters コンテナマスタと事業所の関連情報
 * @param activeOnly activeのもののみにする
 * @returns コンテナマスタ
 */
export const groupsContainerMastersToContainerMasters = (
  groupsContainerMasters: (GroupsContainerMasters | null)[],
  activeOnly = false
): ContainerMaster[] => {
  return groupsContainerMasters
    .filter((containerMaster) => containerMaster?.containerMaster)
    .filter(
      (containerMaster) =>
        !activeOnly || containerMaster?.containerMaster?.active
    )
    .map((groupContainerMaster) => {
      const cm = groupContainerMaster?.containerMaster as ContainerMaster;
      return {
        ...cm,
        // 順番についてはgroup内での順番を利用する
        order: groupContainerMaster?.order ?? cm?.order ?? 999,
      };
    });
};

/**
 * コンテナマスタと事業所の関連情報をSelectableListで利用できる形式に変換する関数
 * @param groupsContainerMasters コンテナマスタと事業所の関連情報
 * @param activeOnly activeのもののみにする
 * @returns SelectableListで利用できるような形式に変換したコンテナマスタ
 */
export const groupsContainerMastersToSelectableListItems = (
  groupsContainerMasters: (GroupsContainerMasters | null)[],
  activeOnly = false
): SelectableListRowType[] => {
  return groupsContainerMasters
    .filter((containerMaster) => containerMaster !== null)
    .filter(
      (containerMaster) =>
        !activeOnly || containerMaster?.containerMaster?.active
    )
    .map((containerMaster) => {
      return {
        id: containerMaster?.containerMaster?.id ?? "",
        name: containerMaster?.containerMaster?.name ?? "",
        order: containerMaster?.order ?? 999,
      };
    });
};

/**
 * コンテナマスタ情報の選択用の変換関数
 * @param containerMasters コンテナマスタ情報
 * @param activeOnly activeのもののみにする
 * @returns SelectableListで利用できるような形式に変換したコンテナマスタ
 */
export const containerMastersToSelectableListItems = (
  containerMasters: ContainerMaster[],
  activeOnly = false
): SelectableListRowType[] => {
  return containerMasters
    .filter((c) => !activeOnly || c.active)
    .map((containerMaster) => {
      return {
        id: containerMaster?.id ?? "",
        name: containerMaster?.name ?? "",
        order: containerMaster?.order ?? 999,
      };
    });
};

/**
 * group.mainContainerMastersと現状の選択されたcontainerMastersを比較し削除、追加、更新の情報を返す
 * @param group group情報
 * @param selectedContainerMasters 選択されたcontainerMasters
 * @returns 削除、追加、更新の情報
 */
export const diffContainerMasters = (
  group: Group,
  selectedContainerMasters: SelectableListRowType[]
): {
  deleted: DeleteGroupsContainerMastersInput[];
  added: CreateGroupsContainerMastersInput[];
  updated: UpdateGroupsContainerMastersInput[];
} => {
  if (!group.mainContainerMasters)
    return { deleted: [], added: [], updated: [] };
  const currentContainerMasters = group.mainContainerMasters?.items ?? [];

  // mainContainerMastersにあって、選択済になかったら削除
  const deleted: DeleteGroupsContainerMastersInput[] = currentContainerMasters
    .filter((c) => !selectedContainerMasters.find((sc) => c && sc.id === c?.id))
    .map((c) => ({ id: c?.id ?? "" }));

  // 選択済にあって、mainContainerMastersになかったら追加
  const added: CreateGroupsContainerMastersInput[] = selectedContainerMasters
    .filter((sc) => !currentContainerMasters.find((c) => c && c?.id === sc.id))
    .map((sc) => ({
      containerMasterId: sc.id,
      groupId: group.id,
      order: sc.order,
    }));

  // containerMasterIdが一致しててorderが異なる場合は更新
  const updated: UpdateGroupsContainerMastersInput[] = selectedContainerMasters
    .filter((sc) =>
      currentContainerMasters.find(
        (c) => c?.id === sc.id && c?.order !== sc.order
      )
    )
    .map((sc) => {
      const c = currentContainerMasters.find((c) => c?.id === sc.id);
      return {
        id: c?.id ?? "",
        order: sc.order,
      };
    });
  return { deleted, added, updated };
};
