import { Container, Vanplan } from "API";

// ラベルに利用するField用の定数
export const DEFAULT_LABEL_FIELD: { label: string; value: string } = {
  label: "C/N",
  value: "cn",
};

export const LABEL_FIELDS: { label: string; value: string }[] = [
  DEFAULT_LABEL_FIELD,
  { label: "指示書No", value: "productSummaryName" },
  { label: "品名", value: "productName" },
  { label: "管理No", value: "managementNumber" },
];

/**
 * バンプラン以下のcontainerの完了率を取得する
 * @param vanplan 対象のバンプラン
 * @returns 完了率
 */
export const getVanplanCompletionRate = (vanplan: Vanplan | null) => {
  if (!vanplan || !vanplan.containers || vanplan.containers.items.length === 0)
    return 0;
  let shippedContainers = 0;
  vanplan.containers.items.forEach((c: Container | null) => {
    if (!c) return;
    if (!c.shipCompleted) return;
    shippedContainers += 1;
  });
  return shippedContainers / vanplan.containers.items.length;
};

/**
 * バンプラン以下のContainerのMasterの情報を文字列にして返す
 * <containerMasterName>x<containerMasterCount>の形式で表示する
 * @param vanplan 対象のバンプラン
 * @returns label: 表示用の文字列, context: すべてのcontainerMasterを表示する文字列
 */
export const containerMasterInfo = (
  vanplan: Vanplan
): { label: string; context: string } => {
  // containerが存在しない場合は空文字を返す
  if (!vanplan.containers?.items) return { label: "", context: "" };

  // containerMasterの種類ごとにカウントする
  const containerMasterCount = vanplan.containers.items.reduce(
    (acc, container) => {
      const containerMaster = container?.containerMaster;
      if (!containerMaster) return acc;

      const name = containerMaster.name;
      const count = acc.get(name)?.count || 0;
      acc.set(name, { count: count + 1, order: containerMaster.order ?? 0 });
      return acc;
    },
    new Map() as Map<string, { count: number; order: number }>
  );

  // orderでソート
  const sortedContainerMaster = Array.from(containerMasterCount.entries()).sort(
    ([, a], [, b]) => a.order - b.order
  );

  // context用にすべてのcontainerMasterを表示する文字列作成(カンマで連結)
  const context = sortedContainerMaster
    .map(([name, { count }]) => {
      return `${name}x${count}`;
    })
    .join(", ");
  // 表示用の文字列作成(Namexcountをカンマで連結するが2セットごとに改行、4種類以降は...にする)
  const label =
    sortedContainerMaster
      .filter((_, index) => index < 4)
      .map(([name, { count }], index) => {
        return `${name}x${count}${index % 2 === 1 ? "\n" : ","}`;
      })
      .join("")
      .slice(0, -1) + (sortedContainerMaster.length > 4 ? "..." : "");
  // labelとcontextを結合して返す
  return {
    label,
    context,
  };
};

/**
 * Containerのm3, weight, ケース個数を合算して返す
 * @param vanplan 対象のバンプラン
 * @returns caseQuantity: ケースの個数, m3: m3の合計, weight: 重量の合計
 */
export const summarizeContainer = (
  vanplan: Vanplan
): { caseQuantity: string; m3: string; weight: string } => {
  // containerが存在しない場合は空文字を返す
  if (!vanplan.containers?.items)
    return { caseQuantity: "", m3: "", weight: "" };

  // containerの情報を合算する
  const caseQuantity = vanplan.containers.items.reduce((acc, container) => {
    return acc + (container?.caseQuantity ?? 0);
  }, 0);
  const m3 = vanplan.containers.items.reduce((acc, container) => {
    return acc + (container?.m3 ?? 0);
  }, 0);
  const weight = vanplan.containers.items.reduce((acc, container) => {
    return acc + (container?.weight ?? 0);
  }, 0);

  return {
    caseQuantity: caseQuantity.toString(),
    m3: (Math.round(m3 * 1000) / 1000).toString(),
    weight: weight.toString(),
  };
};
